<template>
  <v-container fluid class="rai-txt rai-txt--desktop">
    <v-row no-gutters :style="styles">
      <v-col cols="12" sm="5" md="4" lg="3" class="rai-txt-list">
        <slot name="list" />
      </v-col>
      <v-col cols="12" sm="7" md="8" lg="9" class="rai-txt-detail">
        <slot name="detail" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TextingViewDesktop",
  computed: {
    styles() {
      return {
        position: "absolute",
        top: 0,
        left: this.$vuetify.application.left,
        right: this.$vuetify.application.right,
        bottom: this.$vuetify.application.bottom,
        height: `calc(100vh - ${this.$vuetify.application.top}px - ${this.$vuetify.application.bottom}px)`,
        width: "100%",
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        overflow: "hidden",
      };
    },
  },
};
</script>

<style lang="scss">
.rai-texting-list {
  overflow: auto;
}
.rai-txt--desktop {
  .rai-txt-detail {
    overflow-y: scroll;
  }
}
</style>
